import VexDialog from 'vex-dialog';
import Vex from 'vex-js';
import * as focusTrap from 'focus-trap';
import BaseService from '../base/baseService';
import { disableBodyScroll, clearAllBodyScrollLocks } from '../utilities/scroll-lock';
import { appPostMessage } from '../utilities/postMessage';

export default class ModalService extends BaseService {
  get Messages() {
    return {
      [this.CUSTOM_MESSAGES.MODAL_EVENTS.show]: this.showModal,
      [this.CUSTOM_MESSAGES.MODAL_EVENTS.close]: this.closeModals,
      [this.CUSTOM_MESSAGES.MODAL_EVENTS.closeLast]: this.closeLastModal
    };
  }

  get CLASSES() {
    return {
      vex: 'vex',
      active: 'active',
      vex_open: 'vex-open'
    };
  }

  get DEFAULT_OPTS() {
    return {
      content: '',
      unsafeContent: '',
      showCloseButton: true,
      escapeButtonCloses: true,
      overlayClosesOnClick: true,
      appendLocation: 'body',
      className: 'vex-theme-default',
      overlayClassName: '',
      contentClassName: '',
      closeClassName: '',
      closeAllOnPopState: true
    };
  }

  constructor() {
    super();
    this.trap = null;
    this.Vex = Vex;
    this.Vex.registerPlugin(VexDialog);

    // this.handleResolutionChange();
  }

  // handleResolutionChange() {
  //   const startWidth = window.innerWidth;
  //   Bean.on(window, 'resize.modal', (e) => {
  //     if (window.innerWidth !== startWidth) {
  //       const instances = this.Vex.getAll();
  //       if (!this.objectIsEmpty(instances)) this.closeModals();
  //     }
  //   });
  // }

  showModal(opts = {}) {
    const ctx = this;
    if (opts.selector) {
      if (!document.querySelector(opts.selector)) return console.warn('No element found with selector => ', opts.selector);
      opts.unsafeContent = document.querySelector(opts.selector).innerHTML;
    }
    return this.Vex.open({
      ...this.DEFAULT_OPTS,
      ...opts,
      ...{ showCloseButton: false },
      ...{
        afterOpen() {
          const vexInstance = this;
          if (ctx.trap) {
            ctx.trap.deactivate();
          }
          ctx.addAccessibilityAttribute(opts, vexInstance);

          ctx.EMIT(ctx.CUSTOM_MESSAGES.MODAL_EVENTS.afterOpen, { ...vexInstance, pid: opts?.pid });
          ctx.setActiveOnLastVex();
          if (opts.afterOpen) {
            try {
              opts.afterOpen(vexInstance);
            } catch (error) {
              console.error(error);
            }
          }
          document.querySelector('body').classList.add('vex-in');
          if (this.rootEl.classList.contains('js-textarea-scroll-enabled')) {
            disableBodyScroll(document.querySelector('.js-textarea'));
          } else if (this.rootEl.classList.contains('ios-scroll-modal')) {
            var iosScrolls = document.querySelectorAll('.ios-scroll-wrapper');
            iosScrolls.forEach((iosModal) => {
              disableBodyScroll(iosModal);
            });
          } else {
            const targetElements = document.querySelectorAll('.vex-content');
            targetElements.forEach((targetElement) => {
              disableBodyScroll(targetElement);
            });
          }
          var content = document.querySelectorAll('.full-height .vex-content');
          var multisearchContent = document.querySelector('.full-height.multi-search-modal-wrapper .vex-content');
          var wh = window.innerHeight;
          var modalHeight = wh - 55;
          if (!multisearchContent && content.length && window.isMobile()) {
            content.forEach((modal) => {
              modal.setAttribute('style', `min-height: calc(${modalHeight}px) !important; max-height: calc(${modalHeight}px) !important`);
              let ctas = modal.querySelector('.modal-actions');
              window.onresize = (e) => {
                // 200 is a fixed measure - more than 200px is the height of mobile keyboard
                if (wh - window.innerHeight < 200) {
                  var whResize = window.innerHeight;
                  modal.setAttribute('style', `min-height: calc(${whResize}px - 55px) !important; max-height: calc(${whResize}px - 55px) !important`);
                  ctas.classList.remove('d-none');
                } else {
                  ctas.classList.add('d-none');
                }
              };
            });
          }

          ctx.trapModal();
          appPostMessage({ action: 'HIDE_SEARCHBAR', value: true });
          appPostMessage({ action: 'MODAL_OPEN', value: true });
        },
        afterClose() {
          appPostMessage({ action: 'HIDE_SEARCHBAR', value: false });
          appPostMessage({ action: 'MODAL_OPEN', value: false });
          const vexInstance = this;
          ctx.setActiveOnLastVex();
          ctx.EMIT(ctx.CUSTOM_MESSAGES.MODAL_EVENTS.afterClose, { vexInstance });
          if (opts.afterClose) {
            opts.afterClose(vexInstance);
          }
          if (document.querySelector('.vex-content') && document.querySelector('.vex.active')) {
            document.body.classList.add(ctx.CLASSES.vex_open);
          } else {
            document.querySelector('body').classList.remove('vex-in');
            clearAllBodyScrollLocks();
          }
        },
        beforeClose() {
          const vexInstance = this;
          ctx.EMIT(ctx.CUSTOM_MESSAGES.MODAL_EVENTS.beforeClose, { vexInstance });
          if (opts.beforeClose) {
            opts.beforeClose(vexInstance);
          }
          if (Object.values(ctx.Vex.getAll()).length > 0) document.body.classList.remove(ctx.CLASSES.vex_open);
          if (ctx.trap) {
            ctx.trap.deactivate();
          }
        }
      },
      className: `vex-theme-default ${opts.className ? opts.className : ''}`
    });
  }

  setActiveOnLastVex() {
    const vexes = Object.values(this.Vex.getAll());
    if (vexes.length <= 0) return;
    vexes.forEach((v) => v.rootEl.classList.remove(this.CLASSES.active));
    vexes
      .slice(-1)
      .pop()
      .rootEl.classList.add(this.CLASSES.active);
  }

  trapModal() {
    const vexes = Object.values(this.Vex.getAll());
    if (vexes.length <= 0) return;
    const targetElement = vexes.slice(-1).pop().rootEl.firstChild;
    this.trap = focusTrap.createFocusTrap(targetElement, { initialFocus: targetElement, allowOutsideClick: true });
    this.trap.activate();
  }

  closeModals() {
    return this.Vex.closeAll();
  }

  closeLastModal() {
    const vexes = Object.values(this.Vex.getAll());
    return this.Vex.close(vexes.slice(-1).pop());
  }

  addAccessibilityAttribute(opts, vexInstance) {
    let options = { ...{ showCloseButton: true }, ...opts };
    let modalContent = document.querySelector('.vex-content');
    modalContent.setAttribute('role', 'dialog');
    modalContent.setAttribute('aria-modal', 'true');
    let title = opts.ariaLabel ? opts.ariaLabel : modalContent.querySelector('.title') ? modalContent.querySelector('.title').innerHTML : opts.selector.replace('#', '');
    modalContent.setAttribute('aria-label', title);
    //modalContent.setAttribute('tabindex', '-1');
    if (options.showCloseButton) {
      const targetElements = document.querySelectorAll('.vex-content header');
      if (targetElements && targetElements.length > 0) {
        targetElements.forEach((contentEl) => {
          if (!contentEl.parentElement.querySelector('button.vex-close')) {
            var closeEl = (vexInstance.closeEl = document.createElement('button'));
            closeEl.classList.add('vex-close');
            closeEl.setAttribute('aria-label', 'chiudi');
            if (options.closeClassName && typeof options.closeClassName == 'string' && options.closeClassName.length !== 0) {
              var classes = options.closeClassName.split(' ');
              for (var i = 0; i < classes.length; i++) {
                var className = classes[i];
                if (className.length) {
                  closeEl.classList.add(className);
                }
              }
            }
            closeEl.addEventListener('click', vexInstance.close.bind(vexInstance));
            contentEl.parentNode.insertBefore(closeEl, contentEl);
            closeEl.focus();
          } else {
            contentEl.parentElement.querySelector('button.vex-close').focus();
          }
        });
      }
    }
  }
}
