import deepFreeze from '../utilities/freeze';

export default deepFreeze({
  ADDTOCART_EVENTS: {
    pascolAddToCartFeedback: 'product:item:pascolAddToCartFeedback',
    itemAdded: 'item:added:to:cart'
  },
  ADDRESS_DELETED: {
    address: 'address:deleted'
  },
  BACKDROP_EVENTS: {
    animate: 'backdrop:animate'
  },
  BIRTHDAY_UPDATE: {
    updated: 'birthday:updated'
  },
  BODY: {
    TOGGLE_OVERLAY: 'toggle:overlay',
    TOGGLE_SCROLL: 'toogle:scroll'
  },
  CHECKOUT: {
    ENABLE_MOVE_FORWARD: 'checkout.step.enable.move.forward',
    DISABLE_MOVE_FORWARD: 'checkout.step.disable.move.forward',
    ON_CURRENT_STATE: 'checkout.step.on.current.step',
    MAKE_CURRENT_STATE: 'checkout.step.make.current.step',
    PUSH_DEPENDENCY: 'checkout.step.push.dependency',
    REMOVE_DEPENDENCY: 'checkout.step.remove.dependency',
    ADDRESS_CHANGED: 'checkout.address.changed',
    DISABLE_BILLING_ADDRESS: 'checkout.disableBillingAddress',
    ENABLE_BILLING_ADDRESS: 'checkout.enableBillingAddress',
    UPDATE_GIFTCARD_MAX_REACHED: 'checkout.updateGiftcardMaxreached',
    UPDATE_PAYBACK_POINT_USED: 'checkout.updatePaybackPointUsed',
    REMOVE_GIFTCARD: 'checkout.removeGiftcard',
    REMOVE_TICKETS_USE_PAYBACK: 'checkout.hideTicketRestaurantWidget',
    UPDATE_TICKET_WIDGET: 'checkout.updateTicketRestaurantWidget',
    GIFT_CARD_PAID_ALL: 'checkout.giftCardPaidAll'
  },
  DROPDOWN_EVENTS: {
    show: 'dropdown:open',
    hide: 'dropdown:close',
    toclear: 'doropdown:toclear',
    opening: 'dropdown:opening'
  },
  FILTERS_EVENTS: {
    doSearch: 'filters:do:search'
  },
  LOAD_MORE_EVENTS: {
    clicked: 'load:more:products'
  },
  LOADER_EVENTS: {
    show: 'loader:show',
    hide: 'loader:hide'
  },
  MODAL_EVENTS: {
    show: 'modal:show',
    close: 'modal:close',
    closeLast: 'modal:closeLast',
    afterOpen: 'modal:afterOpen',
    afterClose: 'modal:afterClose',
    beforeClose: 'modal:beforeClose',
    serviceReady: 'modal:service:ready',
    openPromoBundleModal: 'modal:promoBundle:opened'
  },
  PRODUCT_ACTION: {
    sendDataLayerInfo: 'product:action:send:data:layer:info',
    viewItem: 'view:item'
  },
  PRODUCT_UPDATE_QTY: {
    quantityupdate: 'product:quantityUpdate',
    stateupdate: 'product:stateUpdate'
  },
  PRODUCT_SEARCH_EVENTS: {
    searchDone: 'product:search:done',
    eraseMultiSearch: 'multisearch:query:erased',
    closeSearchBar: 'searchbar:closed'
  },
  PRODUCT_CLICK_EVENTS: {
    productClicked: 'product:click'
  },
  PROFILE_EVENTS: {
    updated: 'profile:updated',
    updatedAddressList: 'profile:updatedAddressList',
    walletCardRemoved: 'profile:walletCardRemoved'
  },
  SHIPPING_UPDATE_EVENTS: {
    updated: 'shipping:updated'
  },
  SORTING_RULES_EVENTS: {
    changed: 'sortingRiles:changed'
  },
  STORE_SELECTION_SELECT_EVENTS: {
    changed: 'storeSelect:changed',
    open: 'open:layer',
    close: 'close:layer'
  },
  TOOLTIP: {
    EXTERNAL_CLICK_TOOLTIP: 'tooltip.ext.click'
  },
  REORDER_EVENTS: {
    updated: 'reorder.done'
  },
  UPDATE_CART_EVENTS: {
    quantityUpdate: 'cart.quantity.updated',
    deleteLineItem: 'delete.line.item',
    zeroelement: 'zeroelement',
    totalPriceUpdate: 'cart.total.price.updated',
    subTotalPriceUpdate: 'cart.subtotal.price.updated',
    loadMinicart: 'cart.load.minicart',
    closeMinicart: 'cart.close.minicart',
    maxLineItemsExceeded: 'cart.lineitems.exceeded'
  },
  UPDATE_GRID_EVENTS: {
    done: 'update:grid',
    multisearchdone: 'update:multisearch:grid'
  },
  UPDATE_LISTING_EVENTS: {
    done: 'update:listing',
    resetFilters: 'reset:filters'
  },
  BREAKPOINTER: {
    BREAKPOINT_CHANGE: 'breakpoint:change'
  },
  MOBILE_MENU: {
    CLOSE: 'mobileMenu:close'
  },
  SUBSTITUTION_ALERT_EVENTS: {
    changed: 'substitution:alert:changed'
  },
  THUMBNAIL_EVENTS: {
    click: 'thumbnail:click'
  },
  TRACKING_EVENTS: {
    update: 'tracking:update',
    myAccountPromoClick: 'tracking:myAccountPromoClick',
    filtersEvent: 'tracking:filtersEvent',
    newListingFiltersEvent: 'tracking:newListingFiltersEvent',
    newListingSortingEvent: 'tracking:newListingSortingEvent',
    errorEvent: 'tracking:errorEvent',
    newAddToWishlist: 'tracking:newAddToWishlist'
  },
  TABS_EVENTS: {
    activeTabChanged: 'activeTabChanged',
    selectedTabChanged: 'selectedTabChanged',
    CAROUSEL_REMOUNT: 'carouselRemounted',
    FLYERVIEWLIST: 'flyerviewlist'
  },
  CLASS_TOGGLER_EVENTS: {
    toggled: 'classTogglerEvents.toggled'
  },
  TOGGLER_EVENTS: {
    handlerClicked: 'handler.clicked'
  },
  INTERACTION: {
    PUSH: 'interaction.push',
    REMOVE: 'interaction.remove'
  },
  CHECKOUT_TOTAL: {
    CLOSE: 'checkout.total.close'
  },
  PD_TAB: {
    clicked: 'pdtab.clicked'
  },
  WISHLIST_ADD_ALL_EVENTS: {
    show: 'show:btn',
    hide: 'hide:btn'
  },
  ZOOM_EVENTS: {
    click: 'clicked'
  },
  NOTIFICATION: {
    closed: 'notification:closed',
    push: 'notification:push',
    close: 'notification:close',
    pushall: 'notification:pushall',
    pushFirstAvailable: 'notification:pushFirstAvailable',
    updatePromoCart: 'notification:cartUpdate',
    closePromoCart: 'notification.closePromoCart',
    closeStaticBanner: 'notification.closeStaticBanner'
  },
  CART_COLLECTION_EVENTS: {
    update: 'cart_collection_events:update'
  },
  INPUT_FILE_EVENTS: {
    LOADED: 'files.loaded',
    CLEAR_LIST: 'files.clear.list',
    INPUT_FILE_DELETED: 'files.input.deleted'
  },
  VOLANTINI_PRODUCTS: {
    storeUpdated: 'volantinProducts.storeUpdated'
  },
  REVIEWS: {
    GO_TO_PAGE: 'reviews.gotopage',
    HELPFUL: 'reviews.helpful'
  },
  FLYER_EVENTS: {
    selectflyer: 'flyerEvents.selectflyer'
  },
  CAROUSEL_EVENTS: {
    UPDATE_CAROUSEL: 'carouselEvents.update',
    GENERAL_MOUNTED: 'carouselEvents.generalMounted',
    RECOMMENDER_MOUNTED: 'carouselEvents.recommenderMounted'
  },
  PROGRESSIVE_LOAD_PRODUCTS: {
    updategrid: 'update'
  },
  TIMESLOT: {
    UPDATE_PIE: 'timeslot.updatePie',
    EXPIRY: 'timeslot.expiry',
    EXPIRY_NOTIFICATION: 'timeslot.expiryNotification',
    RESERVE_UPDATE: 'timeslot.reserveUpdate',
    INIT_COMPONENT: 'timeslot.initComponent',
    CART_PAYLOAD: 'timeslot.cartPayload'
  }
});
